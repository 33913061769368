//對axios進行配置
import { message } from 'ant-design-vue';
import axios from 'axios'
// 線上測試
import { API_URL } from './apiUrl'
// 本地
import { API } from './apiUrl'
// 線上正式
import {MINI_URL} from './apiUrl'


//創建實例


const Axios = axios.create({
  // baseURL: API_URL,
  // baseURL: API,
  baseURL:MINI_URL,
  timeout: 60000
});

// 請求攔截
Axios.interceptors.request.use(
  config => {
    return config
  },
  err => {
    console.log(err)
  }
)
// 響應攔截
Axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      console.log('response: ', response);
      let res = {}
      res.status = response.status
      res.data = response.data
      return res.data
    }else{
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 404: message.warning({
          content: '網絡請求不存在',
          duration: 3
        })
          break;
        default: message.error({
          content: error.response.message,
          duration: 3
        })
      }
      return Promise.reject(error.response)
    }
  }
)


// // 添加請求攔截器(只要)
// Axios.interceptors.request.use(function (config) {
//   // 在發送請求之前做些什麽
//   //判斷 登錄接口不需要帶token 登陸以外的接口需要帶上token
//   if (config.url != '/userlogin') {
//     //將token放在請求頭中
//     let token = JSON.parse(localStorage.getItem('userInfo')).token;
//     config.headers.authorization = token
//   }
//   return config;
// }, function (error) {
//   // 對請求錯誤做些什麽
//   return Promise.reject(error);
// });

// // 添加響應攔截器
// Axios.interceptors.response.use(function (response) {
//   // 對響應數據做點什麽
//   return response;
// }, function (error) {
//   // 對響應錯誤做點什麽
//   return Promise.reject(error);
// });


export default Axios