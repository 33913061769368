import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {IMG_URL} from '@/api/apiUrl';
import formatTime from '@/utils/formatTime.js'
import formatPrice from '@/utils/formatPrice.js'
import formatUrl from '@/utils/formatUrl.js'
Vue.use(ElementUI);

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);

// import 'lib-flexible'
import Axios from "./api/axios";
//添加到vue實例,方便全局使用
Vue.prototype.$axios = Axios;
// Vue.prototype.$host = "http://localhost:3000/";
Vue.config.productionTip = false;

// 圖片基礎路徑
Vue.prototype.$baseImgUrl = IMG_URL
// 格式化url
Vue.prototype.$formatUrl = formatUrl

// 全局過濾器
Vue.filter('formatTime', formatTime)
Vue.filter('formatPrice', formatPrice)

import "./assets/css/global.css"

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
