import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {

    path: '/',
    name: 'home',
    component: () => import('@/views/home_new.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact/contact.vue')
  },
  {
    path: '/CarDetails',
    name: 'CarDetails',
    component: () => import('@/views/CarDetails/CarDetails.vue')
  },
  {
    path: '/car_list',
    name: 'carList',
    component: () => import('@/views/car-list/carList.vue')
  },
  {
    path: '/LicensePlate',
    name: 'LicensePlate',
    component: () => import('@/views/LicensePlate/LicensePlate.vue')
  },
  {
    path: '/searchtype',
    name: 'searchtype',
    component: () => import('@/views/searchtype/searchtype.vue')
  },
  {
    path: '/SpareParts',
    name: 'SpareParts',
    component: () => import('@/views/SpareParts/SpareParts.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/search/search.vue')
  },
  {
    path: '/CarDetails_album',
    name: 'CarDetails_album',
    component: () => import('@/views/CarDetails/CarDetails_album.vue')
  },
  {
    path: '/car_dealer',
    name: 'carDealer',
    component: () => import('@/views/car-dealer/carDealer.vue')
  },
  {
    path: '/car_zixun',
    name: 'carZixun',
    component: () => import('@/views/car-zixun/carZixun.vue')
  },
  {
    path: '/carZixunDetails',
    name: 'carZixunDetails',
    component: () => import('@/views/car-zixun/carZixunDetails.vue')
  },
  {
    path: '/caradd',
    name: 'caradd',
    component: () => import('@/views/CarDetails/caradd.vue')
  },
  {
    path: '/VehicleAddition',
    name: 'VehicleAddition',
    component: () => import('@/views/VehicleAddition/VehicleAddition.vue')
  },
  {
    path: '/user_login',
    name: 'user_login',
    component: () => import('@/views/user/userLogin.vue')
  },
  {
    path: '/user_register',
    name: 'user_register',
    component: () => import('@/views/user/userRegister.vue')
  },
  {
    path: '/car_shop',
    name: '',
    component: () => import('@/views/CarShop/VehicleDetails_pc.vue'),
    children: [
      {
        name: 'car_shop',
        path: '',
        component: () => import('@/components/carShop/vehicleDetails/introduce_pc.vue')
      },
    ]
  },
  {
    path: '/vehicle_details',
    name: 'vehicleDetails',
    component: () => import('@/views/CarShop/VehicleDetails.vue'),
    children: [
      {
        name: 'vehicleIntroduce',
        path: 'vehicle_introduce',
        component: () => import('@/components/carShop/vehicleDetails/introduce.vue')
      },
    ]
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/admin.vue'),
    redirect: {name: 'index'},
    children:[
      {
        path: '/index',
        name: 'index',
        component: () => import('@/components/admin/index.vue'),
      },
      {
        path: '/car-admin',
        name: 'car-admin',
        component: () => import('@/components/admin/car-admin/car-admin.vue'),
      },
      {
        path: '/car-addcar',
        name: 'car-addcar',
        component: () => import('@/components/admin/car-admin/addcar.vue'),
      },
      {
        path: '/xunjia',
        name: 'xunjia',
        component: () => import('@/components/admin/shangji/xunjia.vue'),
      },
      {
        path: '/yuyue',
        name: 'yuyue',
        component: () => import('@/components/admin/shangji/yuyue.vue'),
      },
      {
        path: '/promotion',
        name: 'promotion',
        component: () => import('@/components/admin/shop-admin/promotion.vue'),
      },
      {
        path: '/sale',
        name: 'sale',
        component: () => import('@/components/admin/shop-admin/sale.vue'),
      },
      {
        path: '/questions',
        name: 'questions',
        component: () => import('@/components/admin/shop-admin/questions.vue'),
      },
      {
        path: '/modify',
        name: 'modify',
        component: () => import('@/components/admin/shop-admin/modify.vue'),
      },
      {
        path: '/picture',
        name: 'picture',
        component: () => import('@/components/admin/shop-admin/picture.vue'),
      },
      {
        path: '/add-sale',
        name: 'add-sale',
        component: () => import('@/components/admin/shop-admin/addsale.vue'),
      },
      {
        path: '/edit-userinfo',
        name: 'edit-userinfo',
        component: () => import('@/components/admin/common/admin-userinfo.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 } // return的結果, 就是 期望滾動到哪個的位置
 }
})
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

// 需要登陸的頁面 [route.name, ...., ...]
const needTokenPages = ['caradd', 'index']

// 全局導航守衛
router.beforeEach((to, from, next) => {
  document.title = "易發易發車網 2828car.com"
  let userInfo = JSON.parse(localStorage.getItem('userInfo') ?? null)

  if(to.name === 'carList' && to.query.q) {
    if(to.fullPath.includes("object")) {
      let qs = JSON.stringify(to.query.q)
      console.log(qs);
      next({name: 'carList', query: {...to.query, q: qs }})
    } else {
      next()
    }
  }

  if(needTokenPages.includes(to.name)) {

    // 未登錄時跳到登陸頁面
    if(!userInfo) {
      Vue.prototype.$message.info('請先登錄', 1)
      next({name: 'user_login', query: {redirect: to.name}})
    }
    next()

  } else if(to.name === 'index' && userInfo) {
    console.log('重定向');
    return next({name: 'index', query: {type: userInfo.userType}})

  } else if(to.name === 'car_shop' && !!window.navigator.userAgent.match(/Mobile/i)) {
    console.log('進入移動端');
    next({name: 'vehicleIntroduce', query: {...to.query}})
  }
  next()
})

export default router
