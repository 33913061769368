import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)


const store = new Vuex.Store({
  state: {
    userInfo: null,
    adminData: {},
    mobileShow:true,
    // 後臺添加車輛後跳轉到管理頁面時傳的lpid
    indexId:"",
  },
  mutations: {
    // 初始化登陸狀態
    getUserInfo(state) {
      let userInfo = localStorage.getItem('userInfo') ?? null
      if(userInfo) {
        state.userInfo = JSON.parse(userInfo)
      }
    },
    // 退出登錄 
    logout(state) {
      state.userInfo = null
    },
    // 獲取個人中心數據
    getAdminData(state, payload) {
      state.adminData = payload
    },
    MobileShow(state){
      state.mobileShow = !state.mobileShow
    },
    getindexId(state, payload){
      state.indexId = payload
    },
  }
})

export default store