/**
 * 
 * @param {string} str 
 * @returns {string}
 */
function luodishijianTime(str) {
  const start = new Date(
    str
  )?.getTime();
  const end = Date.now();
  let time = end - start;
  time = time / (1 * 24 * 60 * 60 * 1000)
  time = Math.floor(time) 

  let delay = time + '天前'

  if(time === 0) {
    delay = '1天內'
  }
  return delay
}

export default luodishijianTime