// 產品的lpid
const lpidObj = {
  car: 1,
  motorcycle: 2
}

function formatPrice(price, lpid) {
  let newPrice = ''
  if(+price === 0) {
    newPrice = '報價'

  } else if(+price > 0 && +lpid === lpidObj.car || +lpid === lpidObj.motorcycle) {
    newPrice = +price + ' 萬'
    
  } else {
    newPrice = +price + ' 元'
  }
  return newPrice;
}

export default formatPrice;
